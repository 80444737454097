import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import * as jwtDecode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginRequest, LoginResponse } from '../models/login-request.model';
import { Session } from '../models/session.model';

@Injectable()
export class SessionService {
    private session: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    $session = this.session.asObservable();

    static readonly SESSION_TAG: string = "a1429483-9bac-4237-b834-9714ad2018ca";

    private endpoit: string = environment.server + 'api/Users/';


    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private toastController: ToastController,
    ) {
        this.loadSession();
    }

    getCurrentSession() {
        return this.session.getValue();
    }

    login(login: LoginRequest) {
        return this.httpClient
            .post(this.endpoit + 'login', login)
            .toPromise()
            .then((res: LoginResponse) => {
                this.saveSession(res.token);
                localStorage.setItem('User', res.userId);
                localStorage.setItem('UserName', login.userName);
                if(res.passwordChangeRequired){
                    this.router.navigate(['/change']);
                }else{
                    this.router.navigate(['/home']);
                }
                this.recargar()
                
            })
            .catch(err => {
                //console.log(err.error.message)
                this.toastController
                    .create({ message: err.error.message, duration: 2000, position: 'top' })
                    .then((t) => {
                        t.present();
                    })
                    .catch();
                throw new Error();
            });
    }
    recargar(){
        window.location.reload()
      }
    closeSession() {
        this.session.next(null);
        localStorage.removeItem(SessionService.SESSION_TAG);
    }

    private loadSession() {
        const token = localStorage.getItem(SessionService.SESSION_TAG);

        if (!token)
            return;

        const session = this.decodeToken(token);

        if (!session)
            return;

        this.session.next(session);
    }

    private saveSession(token: string) {
        if (!token)
            return;

        const session = this.decodeToken(token);

        //console.log(session)

        if (!session)
            return;

        this.session.next(session);
        localStorage.setItem(SessionService.SESSION_TAG, token);
    }

    private decodeToken(token: string): Session {
        if (!token)
            return;

        try {
            var decoded = jwtDecode(token);
            localStorage.setItem('GAP', decoded.IsGlobal);
            localStorage.setItem('LAP', decoded.IsLocal);
            const session: Session = new Session();
            session.token = token;
            return session;
        }
        catch (e) {
            return null;
        }
    }
}